const ALLOWED_PROTOCOLS = new Set(["http:", "https:"]);
const FALLBACK_URL = "/";

function buildUrlObj(url: string): URL | null {
  try {
    return new URL(url, window.location.origin);
  } catch (error) {
    return null;
  }
}

function isAllowedProtocol(urlObj: URL): boolean {
  return ALLOWED_PROTOCOLS.has(urlObj.protocol);
}

function buildRelativeUrl(urlObj: URL): string {
  return urlObj.toString().replace(urlObj.origin, "");
}

export function sanitizeUrlForIFrame(url: string): string {
  const urlObj = buildUrlObj(url);
  if (urlObj === null || !isAllowedProtocol(urlObj)) {
    return FALLBACK_URL;
  }

  return buildRelativeUrl(urlObj);
}

import { domUtils } from "./domUtils";
import { AuthenticationError } from "./errors";
import { httpClient } from "./http";

export const LOGOUT_PATH = "/logout/";
export const LOGIN_PATH = "/login/";
export const redirectToLogout = async () => {
  try {
    await httpClient.post(LOGOUT_PATH);
  } catch (error: unknown) {
    if (error instanceof AuthenticationError) {
      domUtils.getWindow().location.href = LOGIN_PATH;
    } else {
      throw error;
    }
  }
};


import { defineComponent, PropType } from "vue";

import { TestDevice } from "@/api/testdevices";

import TestDeviceCard from "./TestDeviceCard.vue";

export default defineComponent({
  components: {
    TestDeviceCard,
  },
  props: {
    testDevices: {
      type: Object as PropType<TestDevice[]>,
      required: true,
    },
  },
  emits: ["removeDevice"],
});

import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "menu__top col-grow" }
const _hoisted_2 = { class: "menu__bottom col-shrink" }

import { computed, toRefs } from "vue";

import ErrorIndicator from "@/components/shared/ErrorIndicator.vue";
import { Menu } from "@/composables/typedefs";

import NavMenuExpandable from "./NavMenuExpandable.vue";
import { useNavMenu } from "./useNavMenu";


export default /*@__PURE__*/_defineComponent({
  __name: 'NavMenu',
  props: {
    menu: { default: () => ({ products: [], pages: [] }) },
    isLoading: { type: Boolean },
    isError: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const {
  redirectToLogout,
  products,
  productActive,
  productSelect,

  productLines,
  productLineActive,
  productLineSelect,

  features,
  featureActive,
  featureSelect,

  pages,
} = useNavMenu({
  ...toRefs(props),
  locationUrl: window.location.href,
});

const featureSectionBackgroundClass = computed(() => {
  if (!featureActive.value) {
    return "bg-light-blue-1";
  }
  return "bg-grey-1";
});

return (_ctx: any,_cache: any) => {
  const _component_q_spinner_cube = _resolveComponent("q-spinner-cube")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_q_spinner_cube, {
        key: 0,
        size: "xl",
        color: "primary",
        class: "spinner"
      }))
    : (_ctx.isError)
      ? (_openBlock(), _createBlock(ErrorIndicator, {
          key: 1,
          "data-test": "navmenu.error",
          message: "An error has occurred during loading menu"
        }))
      : (_openBlock(), _createBlock(_component_q_list, {
          key: 2,
          class: "text-primar container column justify-between no-wrap"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(NavMenuExpandable, {
                caption: "Product",
                "data-test": "navmenu.product",
                items: _unref(products),
                "selected-item": _unref(productActive),
                onChoose: _unref(productSelect)
              }, null, 8, ["items", "selected-item", "onChoose"]),
              _createVNode(NavMenuExpandable, {
                "data-test": "navmenu.productline",
                caption: "Product Line",
                items: _unref(productLines),
                "selected-item": _unref(productLineActive),
                onChoose: _unref(productLineSelect)
              }, null, 8, ["items", "selected-item", "onChoose"]),
              _createVNode(NavMenuExpandable, {
                class: _normalizeClass(`${featureSectionBackgroundClass.value} menu__feature`),
                "data-test": "navmenu.feature",
                caption: "Feature",
                items: _unref(features),
                "selected-item": _unref(featureActive),
                onChoose: _unref(featureSelect)
              }, null, 8, ["class", "items", "selected-item", "onChoose"])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_q_separator),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pages), (page) => {
                return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                  key: page.name,
                  clickable: "",
                  to: page.route
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_section, { avatar: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_icon, {
                          name: `img:${page.icon}`,
                          height: "25px",
                          fit: "scale-down"
                        }, null, 8, ["name"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_item_section, {
                      class: "label",
                      "data-test": "navmenu.item"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(page.name), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["to"])), [
                  [_directive_ripple]
                ])
              }), 128)),
              _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                clickable: "",
                onClick: _unref(redirectToLogout)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, { avatar: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_icon, { name: "logout" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_item_section, {
                    class: "label",
                    "data-test": "navmenu.item.logout"
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode(" Log out ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])), [
                [_directive_ripple]
              ])
            ])
          ]),
          _: 1
        }))
}
}

})

import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';;

export default {
  emits: Array.from(useDialogPluginComponent.emits),

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
      useDialogPluginComponent();

    return {
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel,
    };
  },
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_q_spinner, {
            size: "50px",
            color: "primary"
          })
        ]))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("iframe", {
      key: _ctx.timestamp,
      "data-test": "fallback.iframe",
      src: _ctx.sanitizedTargetPath,
      frameborder: "0",
      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onIFrameLoad && _ctx.onIFrameLoad(...args)))
    }, null, 40, _hoisted_2)), [
      [_vShow, !_ctx.isLoading]
    ])
  ], 64))
}

import { defineComponent, PropType } from "vue";

import { TestDevice } from "@/api/testdevices";

import TestDeviceCardField from "./TestDeviceCardField.vue";

export default defineComponent({
  components: {
    TestDeviceCardField,
  },
  props: {
    testDevice: {
      type: Object as PropType<TestDevice>,
      required: true,
    },
  },
  emits: ["removeDevice"],
  computed: {
    productLabel(): string {
      return `${this.testDevice.product} / ${this.testDevice.productLine}`;
    },
  },
});

import { castString } from "@/lib/casts";

const RE_URL_PROTOCOL =
  /^((https?|appboy|android-app|intent|ofa|market|opera|opera-mini):\/\/.+)|((smsto:).+)/;

export function isValidMessageUrl(url: string) {
  try {
    new URL(url);
  } catch {
    return false;
  }

  return RE_URL_PROTOCOL.test(url);
}

export interface LegacyUrlMappingParams {
  product: string;
  productLine: string;
  feature: string;
}

export class LegacyMappingError extends Error {}

export function getMappingFromUrl(url: URL): LegacyUrlMappingParams {
  const reUrlSections =
    /(?<feature>\w+\/\w+(-\w+)?)\/(?<product>\w+)\/(?<productLine>\w+)/;
  const urlMatch = reUrlSections.exec(url.pathname);

  let productId = "";
  let productLineId = "";

  try {
    productId = String(castToProductId(urlMatch?.groups?.product ?? ""));
  } catch (error: unknown) {
    if (!(error instanceof LegacyMappingError)) {
      throw error;
    }
  }
  try {
    productLineId = String(
      castToProductLineId(urlMatch?.groups?.productLine ?? "", productId)
    );
  } catch (error: unknown) {
    if (!(error instanceof LegacyMappingError)) {
      throw error;
    }
  }
  return {
    product: productId,
    productLine: productLineId,
    feature: urlMatch?.groups?.feature ?? "",
  };
}

export const LEGACY_URL_MAPPING = {
  products: {
    "1": "Mini",
    "2": "OfA",
  },
  productLines: {
    "1": "Final",
    "2": "Beta",
    "3": "Development",
    "4": "Final",
    "5": "Beta",
    "6": "Development",
  },
  features: {
    "pushnotifications/message-clone": "Push Notifications",
    "pushnotifications/message-list": "Push Notifications",
    "pushnotifications/message": "Push Notifications",
    "pushnotifications/target-list": "PN Targeting",
    "inapps/message-clone": "In Apps",
    "inapps/message-list": "In Apps",
    "inapps/message": "In Apps",
    "inapps/target-list": "IA Targeting",
  },
};

export function castToProductId(value: string): number {
  if (Number.isInteger(Number(value))) {
    return Number(value);
  }
  const result = Object.keys(LEGACY_URL_MAPPING.products).find(
    (key) =>
      LEGACY_URL_MAPPING.products[key as "1" | "2"].toLowerCase() ===
      value.toLowerCase()
  );
  if (result === undefined) {
    throw new LegacyMappingError(`Can't cast value ${value} to Product ID`);
  }
  return Number(result);
}

export function castToProductLineId(
  value: string,
  rawProductId: unknown
): number {
  if (Number.isInteger(Number(value))) {
    return Number(value);
  }
  const productId = castToProductId(castString(rawProductId));

  const availableProductLines = Object.keys(LEGACY_URL_MAPPING.productLines);
  if (productId === 2) {
    // reverse order in array to `.find` last item instead of first
    availableProductLines.reverse();
  } else if (productId !== 1) {
    throw new LegacyMappingError(
      `productId should be '1' or '2', not: ${rawProductId}`
    );
  }
  const result = availableProductLines.find(
    (key) =>
      LEGACY_URL_MAPPING.productLines[
        key as "1" | "2" | "3" | "4" | "5" | "6"
      ].toLowerCase() === value.toLowerCase()
  );
  if (result === undefined) {
    throw new LegacyMappingError(`Can't cast value ${value} to ProductLine ID`);
  }
  return Number(result);
}

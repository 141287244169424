import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "qrCodeCanvas" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref: "dialogRef",
    "data-test": "testdevice.adddialog",
    onHide: _ctx.onDialogHide
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { class: "dialog-card q-dialog-plugin" }, {
        default: _withCtx(() => [
          (_ctx.isError)
            ? (_openBlock(), _createBlock(_component_q_card_section, { key: 0 }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("h3", null, "Failed to fetch registration token", -1)
                ])),
                _: 1
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createElementVNode("div", { class: "text-h4 q-my-md" }, "Register new test device", -1),
                    _createElementVNode("p", null, " Scan the QR code or enter the link below on the device you want to register. ", -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_q_card_section, { class: "qrcode" }, {
                  default: _withCtx(() => [
                    _createElementVNode("canvas", _hoisted_1, null, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_card_section, { "data-test": "testdevice.adddialog.url" }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", { href: _ctx.url }, [
                      _createElementVNode("code", null, _toDisplayString(_ctx.url), 1)
                    ], 8, _hoisted_2)
                  ]),
                  _: 1
                })
              ], 64)),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                label: "Close",
                onClick: _ctx.onDialogOK
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_inner_loading, { showing: _ctx.isLoading }, {
            default: _withCtx(() => [
              _createVNode(_component_q_spinner, {
                size: "50px",
                color: "primary"
              })
            ]),
            _: 1
          }, 8, ["showing"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onHide"]))
}
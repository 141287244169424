import { isRight } from "fp-ts/Either";
import { Type } from "io-ts";
import { Pinia } from "pinia";
import { ComputedRef, Ref } from "vue";

import { ReadablePathReporter } from "@/lib/reporter";

// CastToTypeError means that io-ts casting failed for given object
export class TypeCastError extends Error {}

export function cast<A>(intoType: Type<A>, data: unknown): A {
  const result = intoType.decode(data);

  if (isRight(result)) {
    return result.right;
  }

  throw new TypeCastError(ReadablePathReporter.report(result).join(" "));
}

/**
 * This method can be used to make TypeScript check if your
 * ifs / switch are exhaustive - that they handle each possible value (case)
 * for given type.
 *
 * Check https://stackoverflow.com/q/39419170 for more info how to use it.
 */
export function assertNever(value: never): never {
  throw new Error(`Unexpected value ${value}`);
}

export type Composable<Options, ReturnValue> = (
  options: Options
) => ReturnValue;

export type PiniaStore<Store> = (pinia?: Pinia) => Store;

/**
 * Use this type to make Ref type not mutable. This disallows ref reassignment
 * similarly to how it works with ComputedRef.
 */
export interface ShallowReadonlyRef<T = unknown> extends Ref<T> {
  readonly value: T;
}

/**
 * `ReadonlyRef` is "deep" by default. A shallow alternative to this is
 * `ShallowReadonlyRef`.
 */
export type ReadonlyRef<T = unknown> = ShallowReadonlyRef<Readonly<T>>;

/**
 * Use this type to make ComputedRef type DEEPLY not mutable.
 * const user: ReadonlyComputedRef<User> = computed(() => someUser);
 * user.value.name = "John"; // This will not compile
 */
export type ReadonlyComputedRef<T = unknown> = ComputedRef<Readonly<T>>;

export type WithId<T> = T & { readonly id: string };

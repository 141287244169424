import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref: "dialogRef",
    "data-test": "testdevice.removedialog",
    onHide: $setup.onDialogHide,
    onKeyup: _withKeys($setup.onDialogOK, ["enter"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { class: "q-dialog-plugin" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "text-h4 q-my-md" }, "Test device removal", -1),
              _createElementVNode("p", null, "Are you sure you want to remove this device?", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                color: "negative",
                label: "Remove",
                tabindex: "0",
                "data-test": "testdevice.removedialog.button.remove",
                onClick: $setup.onDialogOK
              }, null, 8, ["onClick"]),
              _createVNode(_component_q_btn, {
                color: "dark",
                label: "Cancel",
                tabindex: "1",
                onClick: $setup.onDialogCancel
              }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onHide", "onKeyup"]))
}
import { RouteRecordRaw } from "vue-router";

import { InAppType } from "@/api/inapps";
import { parseEnvBool } from "@/lib/env";
import { getInAppTypeId } from "@/lib/inapps/inAppType";

import { mapParamsRouteGuard } from "../mapParamsRouteGuard";
import { toNumberOrNull } from "./queryParsing";

const isInAppViewEnabled = parseEnvBool(process.env.VUE_APP_INAPP_VIEW_ENABLED);

const inAppViewSupportingTypes: InAppType[] = [
  "Confirm",
  "Alert",
  "Sheet",
  "WebView Panel",
  "Bottom Sheet",
  "Maintenance Action",
  "Wallpaper Sheet",
  "Wallpaper Sheet Basic",
  "Config Bundle Sheet",
  "Config Bundle Confirm",
  "Center Dialog",
  "Subscription Sheet",
  "Start Page Card",
  "Rate Dialog",
];

function buildInAppRoutes(): RouteRecordRaw[] {
  const routes: RouteRecordRaw[] = [];

  if (isInAppViewEnabled) {
    routes.push({
      name: "InApp",
      path: "/inapps/message/:product(\\d+)/:productLine(\\d+)/:inAppId(\\d+)/",
      component: () => import("@/views/inapps/InApp.vue"),
      props: (route) => {
        return {
          productId: Number(route.params.product),
          productLineId: Number(route.params.productLine),
          inAppId: Number(route.params.inAppId),
          inAppTypeId: null, // It is edit so no inAppTypeId.
        };
      },
    });

    routes.push({
      name: "InAppShortcut",
      path: "/inapps/message/:inAppId(\\d+)?/",
      component: () => import("@/views/inapps/InAppShortcut.vue"),
      props: (route) => {
        return {
          inAppId: route.params.inAppId,
        };
      },
    });

    // Defines routes for all InAppTypes defined in `inAppViewSupportingTypes`.
    for (const inAppType of inAppViewSupportingTypes) {
      const inAppTypeId = getInAppTypeId(inAppType);
      routes.push({
        name: `InAppCreate${inAppType}`,
        path: `/inapps/create-message/:product(\\d+)/:productLine(\\d+)/${inAppTypeId}`,
        component: () => import("@/views/inapps/InApp.vue"),
        props: (route) => {
          return {
            productId: Number(route.params.product),
            productLineId: Number(route.params.productLine),
            inAppId: null, // It is create so no inAppId.
            inAppTypeId: Number(inAppTypeId),
            cloneFromInAppId: toNumberOrNull(route.query, "cloneFromInAppId"),
            cloneFromProductLineId: toNumberOrNull(
              route.query,
              "cloneFromProductLineId"
            ),
          };
        },
      });
    }
  }

  routes.push({
    name: "InAppsList",
    path: "/inapps/message-list/:product(\\w+)/:productLine(\\w+)/",
    component: () => import("@/views/inapps/InApps.vue"),
    beforeEnter: mapParamsRouteGuard,
    props: (route) => {
      return {
        queryParams: route.query,
        product: Number(route.params.productProp),
        productLine: Number(route.params.productLineProp),
      };
    },
  });

  return routes;
}

export const routesInApps: RouteRecordRaw[] = buildInAppRoutes();

import useQuasar from 'quasar/src/composables/use-quasar.js';;

import TestDeviceRemoveDialog from "@/components/testdevices/dialogs/TestDeviceRemoveDialog.vue";

interface TestDeviceRemoveDialogOptions {
  onOk: (deviceId: number) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useTestDevicesRemoveDialog(
  options: TestDeviceRemoveDialogOptions
) {
  const $q = useQuasar();

  const openDialog = (deviceId: number) => {
    $q.dialog({
      component: TestDeviceRemoveDialog,
    }).onOk(() => options.onOk(deviceId));
  };

  return {
    openDialog,
  };
}

export class AuthenticationError extends Error {}
export class StateMachineError extends Error {}
export class APIError extends Error {}

function buildDefaultErrorMessage(
  httpCode: number,
  httpStatusText: string
): string {
  return `Response failed with status: ${httpCode} and description: ${httpStatusText}`;
}

function serializeToPrettyErrorString(
  valueToSerialize: unknown,
  didRecursionStart = false
): string {
  if (valueToSerialize === null || typeof valueToSerialize === "undefined") {
    return String(valueToSerialize);
  } else if (Array.isArray(valueToSerialize)) {
    const joinString = didRecursionStart ? ", " : "\n\n";
    return valueToSerialize
      .map((item) => serializeToPrettyErrorString(item, true))
      .join(joinString);
  } else if (typeof valueToSerialize === "object") {
    const resultAsStrings = Object.entries(valueToSerialize).map(
      ([key, value]) => `${key} - ${serializeToPrettyErrorString(value, true)}`
    );
    return resultAsStrings.join("\n");
  }
  return String(valueToSerialize);
}

export function buildErrorMessage(
  httpCode: number,
  httpStatusText: string,
  data: unknown
): string {
  if (typeof data !== "object" || data === null) {
    return buildDefaultErrorMessage(httpCode, httpStatusText);
  }

  if ("detail" in data) {
    return String((data as { detail?: unknown }).detail);
  }

  if ("message" in data) {
    return serializeToPrettyErrorString(data.message);
  }

  const fieldErrors: string[] = [];

  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      if (value.every((item) => typeof item === "string")) {
        fieldErrors.push(`${key}: ${value.join(", ")}`);
      } else {
        fieldErrors.push(`${key}: ${JSON.stringify(value)}`);
      }
    } else {
      fieldErrors.push(`${key}: ${value}`);
    }
  }

  return fieldErrors.join(", ");
}

export function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }
  throw new Error(`Unexpected error has occurred: ${error}`);
}

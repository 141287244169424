import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "test-devices q-pa-md" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "column"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_test_device_header = _resolveComponent("test-device-header")!
  const _component_test_device_cards = _resolveComponent("test-device-cards")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Failed to fetch list of test devices"))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_test_device_header, {
            "add-new-device-disabled": _ctx.isLoading,
            onAddNewDeviceClick: _ctx.openAddDialog
          }, null, 8, ["add-new-device-disabled", "onAddNewDeviceClick"]),
          (_ctx.hasTestDevices)
            ? (_openBlock(), _createBlock(_component_test_device_cards, {
                key: 0,
                "test-devices": _ctx.testDevices,
                onRemoveDevice: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openRemoveDialog($event)))
              }, null, 8, ["test-devices"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, " You have no test devices. Register one using \"Add new test device\" button "))
        ])),
    _createVNode(_component_q_inner_loading, { showing: _ctx.isLoading }, {
      default: _withCtx(() => [
        _createVNode(_component_q_spinner, {
          size: "50px",
          color: "primary"
        })
      ]),
      _: 1
    }, 8, ["showing"])
  ]))
}
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-h2 q-my-md" }, "Test devices", -1)),
    _createElementVNode("div", null, [
      _createVNode(_component_q_btn, {
        icon: "add",
        class: "q-ml-md",
        color: "primary",
        disable: _ctx.addNewDeviceDisabled,
        "data-test": "testdevice.header.button.add",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addNewDeviceClick')))
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Add new test device")
        ])),
        _: 1
      }, 8, ["disable"])
    ])
  ]))
}
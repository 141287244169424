
import { defineComponent, PropType, toRefs } from "vue";

import { NavMenuItem } from "@/composables/typedefs";

import { useNavMenuExpandable } from "./useNavMenuExpandable";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<NavMenuItem[]>,
      required: true,
    },
    selectedItem: {
      type: Object as PropType<NavMenuItem | null>,
      default: (): NavMenuItem => {
        return {
          name: "",
          icon: "",
        };
      },
    },
    caption: {
      type: String,
      required: true,
    },
  },
  emits: ["choose"],
  setup(props, { emit }) {
    return useNavMenuExpandable({
      ...toRefs(props),
      itemClickedCallback: (item) => emit("choose", item),
    });
  },
});

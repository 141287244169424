import { domUtils } from "@/lib/domUtils";

import { LocationChangeMessagePayload } from "./typedefs";

function updateWindowState(payload: LocationChangeMessagePayload) {
  if (
    !(typeof payload.location === "string" && typeof payload.title === "string")
  ) {
    return;
  }

  const windowObj = domUtils.getWindow();

  windowObj.history.replaceState(
    windowObj.history.state,
    payload.title,
    payload.location
  );

  windowObj.document.title = payload.title;
}

export function handleLocationChange(payload: LocationChangeMessagePayload) {
  if (payload.title === "Login") {
    domUtils.getWindow().location.href = payload.location;
  } else {
    updateWindowState(payload);
  }
}

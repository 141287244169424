import { RouteRecordRaw } from "vue-router";

import TestDevices from "@/views/testdevices/TestDevices.vue";

export const routesTestDevices: RouteRecordRaw[] = [
  {
    name: "TestDevicesList",
    path: "/testdevices/testdevices-list",
    component: TestDevices,
  },
];

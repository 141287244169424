import * as t from "io-ts";

import {
  BasePermissionPrefix,
  BasePermissionType,
  InAppsPermissionPrefix,
  InAppsPermissionType,
  PushMessagesPermissionPrefix,
  PushMessagesPermissionType,
} from "@/api/permissions/typedefs";
import { Tag } from "@/api/tags/typedefs";
import { ProductLine } from "@/lib/productLine";

export interface NavMenuItem {
  name: string;
  icon: string;
  route?: string;
  productLines?: NavMenuItem[];
  features?: NavMenuItem[];
}
export interface Menu {
  products: NavMenuItem[];
  pages: NavMenuItem[];
}

export const UserDataGuard = t.type({
  username: t.string,
  isSuperuser: t.boolean,
});

export interface Config {
  menu: Menu;
  defaultTags: Tag[];
}

export enum DataState {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  LOADED = "LOADED",
  ERROR = "ERROR",
}

export interface InAppsPermissionConfig {
  prefix: InAppsPermissionPrefix;
  permissionType: InAppsPermissionType;
  productLine: ProductLine;
}

export interface PushMessagesPermissionConfig {
  prefix: PushMessagesPermissionPrefix;
  permissionType: PushMessagesPermissionType;
  productLine: ProductLine;
}

export interface BasePermissionConfig {
  prefix: BasePermissionPrefix;
  permissionType: BasePermissionType;
  productLine?: never;
}

export type PermissionConfig =
  | InAppsPermissionConfig
  | PushMessagesPermissionConfig
  | BasePermissionConfig;

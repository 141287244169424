import * as t from "io-ts";
import { fromNullable } from "io-ts-types";

const EMPTY_VALUE = "-";

export const TestDeviceGuard = t.type({
  id: t.number,
  product: t.string,
  productLine: t.string,
  leanplumUserId: t.string,
  deviceName: fromNullable(t.string, EMPTY_VALUE),
});

export type TestDevice = t.TypeOf<typeof TestDeviceGuard>;

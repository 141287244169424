import { computed, ref, watch } from "vue";
import { Router } from "vue-router";

import { AuthenticationError } from "@/lib/errors";
import { ShallowReadonlyRef } from "@/lib/typing";

import { DataState } from "./composables/typedefs";
import { useConfigData } from "./composables/useConfigData";
import { useUserData } from "./composables/useUserData";
import { Sentry } from "./lib/sentry";

export interface UseAppOptions {
  router: ShallowReadonlyRef<Router>;
  sentry: ShallowReadonlyRef<Sentry>;
}
export function useApp({ router, sentry }: UseAppOptions) {
  const leftDrawerOpen = ref(false);

  const {
    currentUserName,
    state: userState,
    fetchCurrentUserData: fetchUser,
  } = useUserData();

  const isUserLoading = computed(() => userState.value === DataState.LOADING);
  const isUserError = computed(() => userState.value === DataState.ERROR);
  const isMenuLoading = computed(() => configState.value === DataState.LOADING);
  const isMenuError = computed(() => configState.value === DataState.ERROR);

  watch([currentUserName], ([newUsername]) => {
    if (newUsername === null) {
      sentry.value.setUser(null);
    } else {
      sentry.value.setUser({ username: newUsername });
    }
  });

  const {
    navMenu: menu,
    state: configState,
    fetchConfig,
    config,
  } = useConfigData();

  const toggleLeftDrawer = () => {
    leftDrawerOpen.value = !leftDrawerOpen.value;
  };

  const fetchOrRouteToLogin = async () => {
    try {
      await fetchUser();
      await fetchConfig();
    } catch (error: unknown) {
      if (!(error instanceof AuthenticationError)) {
        // Menu error should be set here as fetchConfig won't be ever called if fetchUser failed
        configState.value = DataState.ERROR;
        throw error;
      }
      router.value.push({ name: "Login" });
    }
  };

  return {
    isUserError,
    isUserLoading,
    isMenuLoading,
    isMenuError,
    menu,
    toggleLeftDrawer,
    fetchOrRouteToLogin,
    leftDrawerOpen,
    currentUserName,
    config,
  };
}

import { RouteLocationRaw, RouteRecordRaw } from "vue-router";

import Fallback from "@/views/fallback/Fallback.vue";

export const routesFallback: RouteRecordRaw[] = [
  {
    path: "/:targetPath(.*)*",
    name: "DispatchFallback",
    redirect: (to): RouteLocationRaw => {
      return {
        name: "Fallback",
        params: {
          timestamp: new Date().getTime(),
          targetPath: to.fullPath,
        },
      };
    },
  },
  {
    name: "Fallback",
    path: "/fallback/:targetPath(.*)",
    component: Fallback,
    props: true,
  },
];

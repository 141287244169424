<template>
  <div class="home">
    <div class="text-h4">Welcome to PNS Console</div>
    Choose one of the options on the left menu or contact the administrator to
    gain access
  </div>
</template>
<style lang="scss" scoped>
.home {
  padding: 1rem;
}
</style>

import { QVueGlobals } from "quasar/dist/types";
import { ComputedRef, Ref } from "vue";
import { Router } from "vue-router";

import { convertIFrameMessageData, MessageType } from "@/lib/iframe";
import { Composable } from "@/lib/typing";
import { getMappingFromUrl } from "@/lib/url";
import { LocationChangeMessagePayload } from "@/views/fallback/typedefs";

enum FormStatusResult {
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export type UseFallbackOptions = {
  router: Router;
  quasar: QVueGlobals;
  targetPath: Ref<string>;
  sanitizedTargetPath: ComputedRef<string>;
  locationChangeCallback: (payload: LocationChangeMessagePayload) => void;
};

export type UseFallbackReturnType = {
  eventHandler: (event: MessageEvent<unknown>) => void;
};

export const useFallback: Composable<
  UseFallbackOptions,
  UseFallbackReturnType
> = (options) => {
  const handleMessageTypeFormStatus = (
    eventStatus: FormStatusResult.SUCCEEDED | FormStatusResult.FAILED,
    eventMessage: string
  ) => {
    if (eventStatus === FormStatusResult.SUCCEEDED) {
      if (options.router.currentRoute.value.path.includes("target")) {
        options.router.push({
          name: "TargetingList",
          params: {
            targetType: options.sanitizedTargetPath.value.split("/")[1],
            product: options.targetPath.value.split("/")[3],
            productLine: options.targetPath.value.split("/")[4],
          },
        });
        options.quasar.notify({
          type: "positive",
          message: eventMessage || "Success",
        });
      }
    } else {
      options.quasar.notify({
        type: "negative",
        message: eventMessage || "Failure",
      });
    }
  };

  const handleMessageTypeGoToInappList = () => {
    const url = new URL(window.location.href);
    const { product, productLine } = getMappingFromUrl(url);
    options.router.push({
      name: "InAppsList",
      params: {
        product,
        productLine,
      },
    });
  };

  const eventHandler = (event: MessageEvent<unknown>) => {
    const messageData = convertIFrameMessageData(event.data);
    if (messageData?.type === MessageType.FORM_STATUS) {
      handleMessageTypeFormStatus(
        messageData.payload.status as FormStatusResult,
        messageData.payload.message
      );
    }
    if (messageData?.type === MessageType.GO_TO_INAPP_LIST) {
      handleMessageTypeGoToInappList();
    }
    if (messageData?.type !== MessageType.LOCATION_CHANGE) return;

    options.locationChangeCallback(messageData.payload);
  };

  return {
    eventHandler,
  };
};


import { computed, defineComponent } from "vue";

import TestDeviceCards from "@/components/testdevices/TestDeviceCards.vue";
import TestDeviceHeader from "@/components/testdevices/TestDeviceHeader.vue";
import { useTestDevicesAddDialog } from "@/composables/testdevices/useTestDevicesAddDialog";
import { useTestDevicesList } from "@/composables/testdevices/useTestDevicesList";
import { useTestDevicesRemoveDialog } from "@/composables/testdevices/useTestDevicesRemoveDialog";
import { DataState } from "@/composables/typedefs";

export default defineComponent({
  components: {
    TestDeviceCards,
    TestDeviceHeader,
  },
  setup() {
    const { testDevices, tryToDeleteTestDevice, tryToFetchTestDevices, state } =
      useTestDevicesList();

    const { openDialog: openAddDialog } = useTestDevicesAddDialog({
      onDismiss: () => tryToFetchTestDevices(),
    });

    const { openDialog: openRemoveDialog } = useTestDevicesRemoveDialog({
      onOk: (deviceId) => tryToDeleteTestDevice(deviceId),
    });

    const hasTestDevices = computed(() => testDevices.value.length > 0);

    return {
      tryToDeleteTestDevice,
      openAddDialog,
      openRemoveDialog,
      testDevices,
      hasTestDevices,
      isLoading: computed(() => state.value === DataState.LOADING),
      isError: computed(() => state.value === DataState.ERROR),
    };
  },
});

import { fold } from "fp-ts/lib/Either";
import { Context, getFunctionName, ValidationError } from "io-ts";
import { Reporter } from "io-ts/Reporter";

function stringify(v: unknown): string {
  if (typeof v === "function") {
    return getFunctionName(v);
  }
  if (typeof v === "number" && !isFinite(v)) {
    if (isNaN(v)) {
      return "NaN";
    }
    return v > 0 ? "Infinity" : "-Infinity";
  }
  return JSON.stringify(v);
}

function getContextPath(context: Context): string {
  if (context.length === 0) return "";

  // Keep the first key (usually the root object name)
  let path = context[0].key;

  // Add the last two keys if they exist
  if (context.length > 2) {
    path +=
      "..." +
      context
        .slice(-2)
        .map((c) => c.key)
        .join(".");
  } else if (context.length === 2) {
    path += "." + context[1].key;
  }

  return path;
}

function getMessage(e: ValidationError): string {
  const contextPath = getContextPath(e.context);
  const value = stringify(e.value);
  const key = e.context[e.context.length - 1].key;
  const expectedType = e.context[e.context.length - 1].type.name;

  if (e.message !== undefined) {
    return `Error at ${contextPath}: ${e.message}.`;
  }

  if (key) {
    return `Invalid value ${value} of key ${key} supplied to ${contextPath}. Expected ${expectedType}.`;
  }

  return `Invalid value ${value} supplied to ${contextPath}. Expected ${expectedType}.`;
}

export function failure(es: Array<ValidationError>): Array<string> {
  return es.map(getMessage);
}

export function success(): Array<string> {
  return ["No errors!"];
}

export const ReadablePathReporter: Reporter<Array<string>> = {
  report: fold(failure, success),
};

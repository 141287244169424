import useQuasar from 'quasar/src/composables/use-quasar.js';;

import TestDeviceAddDialog from "@/components/testdevices/dialogs/TestDeviceAddDialog.vue";

interface TestDeviceAddDialogOptions {
  onDismiss: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useTestDevicesAddDialog(options: TestDeviceAddDialogOptions) {
  const $q = useQuasar();

  const openDialog = () => {
    const dialog = $q.dialog({
      component: TestDeviceAddDialog,
    });
    dialog.onDismiss(() => options.onDismiss());
  };

  return {
    openDialog,
  };
}


import { defineComponent } from "@vue/runtime-core";
import QRCode from "qrcode";
import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';;
import { computed, Ref, ref, watch } from "vue";

import { useTestDevicesToken } from "@/composables/testdevices/useTestDevicesToken";
import { DataState } from "@/composables/typedefs";

export default defineComponent({
  emits: Array.from(useDialogPluginComponent.emits),
  setup() {
    const { dialogRef, onDialogOK, onDialogHide } = useDialogPluginComponent();
    const { url, state } = useTestDevicesToken();
    const qrCodeCanvas: Ref<HTMLCanvasElement | null> = ref(null);

    watch([url], () => {
      const canvasEl = qrCodeCanvas.value;
      const qrCodeUrl = url.value;
      if (canvasEl === null || qrCodeUrl === "") {
        return;
      }

      QRCode.toCanvas(canvasEl, qrCodeUrl);
    });

    return {
      url,
      qrCodeCanvas,
      isLoading: computed(() => state.value === DataState.LOADING),
      isError: computed(() => state.value === DataState.ERROR),
      onDialogHide,
      dialogRef,
      onDialogOK,
    };
  },
});

import { InAppType } from "@/api/inapps";

class InAppTypeError extends Error {}
export class InvalidInAppTypeIdError extends InAppTypeError {}
export class InvalidInAppTypeNameError extends InAppTypeError {}

interface InAppTypeData {
  id: number;
  name: InAppType;
}

export const IN_APP_TYPES: InAppTypeData[] = [
  {
    id: 1,
    name: "Center Dialog",
  },
  {
    id: 2,
    name: "Bottom Sheet",
  },
  {
    id: 3,
    name: "Alert",
  },
  {
    id: 4,
    name: "Confirm",
  },
  {
    id: 5,
    name: "Sheet",
  },
  {
    id: 6,
    name: "WebView Panel",
  },
  {
    id: 7,
    name: "Maintenance Action",
  },
  {
    id: 8,
    name: "Wallpaper Sheet",
  },
  {
    id: 9,
    name: "Config Bundle Sheet",
  },
  {
    id: 10,
    name: "Config Bundle Confirm",
  },
  {
    id: 11,
    name: "Wallpaper Sheet Basic",
  },
  {
    id: 12,
    name: "Subscription Sheet",
  },
  {
    id: 13,
    name: "Start Page Card",
  },
  {
    id: 14,
    name: "Rate Dialog",
  },
];

export function getInAppTypeId(inAppTypeName: string): number {
  const inAppTypeId = IN_APP_TYPES.find(
    (inApp) => inApp.name === inAppTypeName
  )?.id;

  if (inAppTypeId === undefined) {
    throw new InvalidInAppTypeNameError(
      `Failed to find id of in app type: ${inAppTypeName}`
    );
  }

  return inAppTypeId;
}

export function getInAppType(inAppTypeId: number): InAppType {
  const inAppType = IN_APP_TYPES.find(
    (inApp) => inApp.id === inAppTypeId
  )?.name;

  if (inAppType === undefined) {
    throw new InvalidInAppTypeIdError(
      `Failed to find name of in app type with id: ${inAppTypeId}`
    );
  }

  return inAppType;
}

import { Ref, ref } from "vue";

import { fetchTestDeviceUrl } from "@/api/testdevices/token";
import { DataState } from "@/composables/typedefs";

export function useTestDevicesToken() {
  const state: Ref<DataState> = ref(DataState.INITIAL);
  const url: Ref<string> = ref("");

  const tryToFetchTestDevicesUrl = async () => {
    try {
      url.value = await fetchTestDeviceUrl();
      state.value = DataState.LOADED;
    } catch (error: unknown) {
      state.value = DataState.ERROR;
    }
  };

  tryToFetchTestDevicesUrl();

  return {
    url,
    state,
  };
}

// This file contains TypeScript util methods to enforce type casting.
// Do not write functions for runtime checks here.

export function castNumber(value: unknown): number {
  if (typeof value !== "number") {
    throw new Error(`Expected ${value} to be number, got ${typeof value}`);
  }
  return value;
}

export function castString(value: unknown): string {
  if (typeof value !== "string") {
    throw new Error(`Expected ${value} to be string, got ${typeof value}`);
  }
  return value;
}

export function castBoolean(value: unknown): boolean {
  if (typeof value !== "boolean") {
    throw new Error(`Expected ${value} to be boolean, got ${typeof value}`);
  }
  return value;
}

export function castExists<T>(value: T | undefined | null): T {
  if (value === undefined || value === null) {
    throw new Error(`Unexpected ${value}.`);
  }
  return value;
}

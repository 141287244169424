import camelcaseKeys from "camelcase-keys";

import { httpClient, QueryParams } from "@/lib/http";
import { cast } from "@/lib/typing";

import { TestDevice, TestDeviceGuard } from "./typedefs";

const TEST_DEVICES_URL = "/api/testdevices/";

export async function fetchTestDevicesCount(
  queryParams?: QueryParams
): Promise<number> {
  const response = await httpClient.get(TEST_DEVICES_URL, queryParams);
  const responseBody = await response.json();
  // NOTE: When there is pagination, backend returns paginated items at 'results' attribute.
  // Otherwise it returns array of all items.
  const count = responseBody["count"] ?? responseBody?.length ?? 0;
  return count;
}

export function convertTestDevice(data: unknown): TestDevice {
  const testDeviceData = data as {
    id?: unknown;
    product?: unknown;
    product_line?: unknown;
    leanplum_user_id?: unknown;
    device_name?: unknown;
  };

  // TODO(PNS-1738): Drop when BE will start sending camelCase.
  const camelCasedData = camelcaseKeys({ ...testDeviceData });
  return cast(TestDeviceGuard, camelCasedData);
}

export function convertTestDevices(data: unknown): TestDevice[] {
  if (!Array.isArray(data)) return [];
  return data.map((testDevice) => convertTestDevice(testDevice));
}

export async function fetchTestDevices(): Promise<TestDevice[]> {
  const response = await httpClient.get(TEST_DEVICES_URL);
  const data = await response.json();
  return convertTestDevices(data);
}

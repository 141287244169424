
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    addNewDeviceDisabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["addNewDeviceClick"],
});

import * as Sentry from "@sentry/vue";
import { inspect } from "@xstate/inspect";
import { createPinia } from "pinia";
import Quasar from 'quasar/src/vue-plugin.js';;
import { createApp } from "vue";

import { httpClient } from "@/lib/http";

import App from "./App.vue";
import { parseEnvBool } from "./lib/env";
import { getEnvironment } from "./lib/environment";
import quasarUserOptions from "./quasar-user-options";
import { routerFactory } from "./router";

if (parseEnvBool(process.env.VUE_APP_DEBUG_XSTATE)) {
  inspect({
    iframe: false,
  });
}

const router = routerFactory();
const app = createApp(App, {
  sentry: Sentry,
  router,
});

httpClient.setOnUnauthorizedCallback(() => router.push({ name: "Login" }));

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    tracesSampleRate: 1.0,
    environment: getEnvironment() ?? undefined,
    ignoreErrors: [
      // Error thrown by console BE when user session has expired.
      "Server returned 401 Unauthorized response when requested",
    ],
  });
}

const pinia = createPinia();

app.use(pinia).use(Quasar, quasarUserOptions).use(router).mount("#app");

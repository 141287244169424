import { LocationQuery } from "vue-router";

export function toNumberOrNull(
  query: LocationQuery,
  key: string
): number | null {
  const rawValue = query[key];
  if (!rawValue) return null;

  const value = Number(rawValue);
  if (!Number.isInteger(value)) return null;

  return value;
}

import { computed, ComputedRef, ref, Ref, watch } from "vue";
import { useRouter } from "vue-router";

import { Menu, NavMenuItem } from "@/composables/typedefs";
import { redirectToLogout } from "@/lib/logout";
import { getMappingFromUrl, LEGACY_URL_MAPPING } from "@/lib/url";

export interface UseNavMenuOptions {
  menu: Ref<Partial<Menu>>;
  isLoading: Ref<boolean>;
  isError: Ref<boolean>;
  locationUrl?: string;
}

// TODO(PNS-1993): Drop legacy url mapping when deleting Fallback.vue
export const TEST_ONLY = {
  getMappingFromUrl,
};

export function useNavMenu({
  menu,
  isLoading,
  locationUrl = "",
}: UseNavMenuOptions) {
  // Routing
  const router = useRouter();

  // Product
  const products: ComputedRef<NavMenuItem[]> = computed(() => {
    return menu.value?.products ?? [];
  });
  const productActive: Ref<NavMenuItem | null> = ref(null);
  const productSelect = (product: NavMenuItem) => {
    const matchingProductLine = productLineMatch(product);
    productLineSelect(matchingProductLine);
    productActive.value = product;
  };

  // ProductLine
  const productLines: ComputedRef<NavMenuItem[]> = computed(() => {
    const result: NavMenuItem[] = productActive.value?.productLines ?? [];
    return result;
  });
  const productLineActive: Ref<NavMenuItem | null> = ref(null);
  const productLineSelect = (productLine: NavMenuItem | null) => {
    if (featureActive.value) {
      let matchingFeature: NavMenuItem | null = null;
      if (productLine) {
        matchingFeature = featureMatch(productLine);
      }
      featureSelect(matchingFeature);
    }
    productLineActive.value = productLine;
  };
  const productLineMatch = (
    productToMatchFrom: NavMenuItem
  ): NavMenuItem | null => {
    const matchingProductsLines =
      productToMatchFrom.productLines?.filter((productLine: NavMenuItem) => {
        if (productLine.name === productLineActive.value?.name) {
          return productLine;
        }
      }) ?? [];
    if (matchingProductsLines.length !== 1) {
      return null;
    }
    return matchingProductsLines[0];
  };

  // Feature
  const features: ComputedRef<NavMenuItem[]> = computed(() => {
    return productLineActive.value?.features ?? [];
  });
  const featureActive: Ref<NavMenuItem | null> = ref(null);
  const featureSelect = (feature: NavMenuItem | null) => {
    featureActive.value = feature;
    router.push((feature?.route as string) ?? { name: "NotFound" });
  };
  const featureMatch = (
    productLineToMatchFrom: NavMenuItem
  ): NavMenuItem | null => {
    const matchingFeatures: NavMenuItem[] =
      productLineToMatchFrom?.features?.filter((feature: NavMenuItem) => {
        if (feature.name === featureActive.value?.name) {
          return feature;
        }
      }) ?? [];

    if (matchingFeatures.length !== 1) {
      return null;
    }
    return matchingFeatures[0];
  };

  // Page
  const pages: ComputedRef<NavMenuItem[]> = computed(() => {
    return menu.value?.pages ?? [];
  });
  const pageActive: Ref<NavMenuItem | null> = ref(null);

  // Other
  const selectActiveItemsAccordingToURL = (): void => {
    if (!locationUrl) {
      return;
    }
    const url = new URL(locationUrl);
    const {
      product: matchedProduct,
      productLine: matchedProductLine,
      feature: matchedFeature,
    } = getMappingFromUrl(url);
    // @ts-expect-error: Blind querying LEGACY_URL_MAPPING.
    const initialProductName = LEGACY_URL_MAPPING.products[matchedProduct];
    const initialProductLineName =
      // @ts-expect-error: Blind querying LEGACY_URL_MAPPING.
      LEGACY_URL_MAPPING.productLines[matchedProductLine];
    const initialFeatureName =
      // @ts-expect-error: Blind querying LEGACY_URL_MAPPING.
      LEGACY_URL_MAPPING.features[matchedFeature];

    productActive.value =
      products.value.find((product) => product.name === initialProductName) ??
      null;
    productLineActive.value =
      productLines.value.find(
        (productLine) => productLine.name === initialProductLineName
      ) ?? null;
    featureActive.value =
      features.value.find((feature) => feature.name === initialFeatureName) ??
      null;
  };
  watch(isLoading, selectActiveItemsAccordingToURL, {
    immediate: true,
  });

  return {
    redirectToLogout,
    products,
    productActive,
    productSelect,

    productLines,
    productLineActive,
    productLineSelect,

    features,
    featureActive,
    featureSelect,

    pages,
    pageActive,
  };
}

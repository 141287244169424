/**
 * Wrapper over window object for easier testing.
 */
function getWindow(): typeof window {
  return window;
}

export const domUtils = {
  getWindow,
};

import { Ref, ref } from "vue";

import { deleteTestDevice } from "@/api/testdevices/delete";
import { fetchTestDevices } from "@/api/testdevices/list";
import { TestDevice } from "@/api/testdevices/typedefs";

import { DataState } from "../typedefs";

export function useTestDevicesList() {
  const testDevices: Ref<TestDevice[]> = ref([]);
  const state: Ref<DataState> = ref(DataState.INITIAL);

  const tryToFetchTestDevices = async () => {
    state.value = DataState.LOADING;
    try {
      testDevices.value = await fetchTestDevices();
      state.value = DataState.LOADED;
    } catch (error: unknown) {
      state.value = DataState.ERROR;
      throw error;
    }
  };

  const waitForLoad = tryToFetchTestDevices();

  const tryToDeleteTestDevice = async (deviceId: number) => {
    try {
      await deleteTestDevice(deviceId);
      testDevices.value = testDevices.value.filter(
        (testDevice) => testDevice.id !== deviceId
      );
    } catch (error: unknown) {
      state.value = DataState.ERROR;
      throw error;
    }
  };

  return {
    tryToFetchTestDevices,
    waitForLoad,
    tryToDeleteTestDevice,
    testDevices,
    state,
  };
}

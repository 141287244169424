import { RouteRecordRaw } from "vue-router";

import { mapParamsRouteGuard } from "../mapParamsRouteGuard";

export const routesPushMessages: RouteRecordRaw[] = [
  {
    name: "PushMessage",
    path: "/pushnotifications/message/:product(\\w+)/:productLine(\\w+)/:pushMessageId(\\d+)?/",
    component: () => import("@/views/pushmessages/PushMessage.vue"),
    beforeEnter: mapParamsRouteGuard,
    props: (route) => {
      return {
        pushMessageId: route.params.pushMessageId,
      };
    },
  },
  {
    name: "PushMessagesList",
    path: "/pushnotifications/message-list/:product(\\w+)/:productLine(\\w+)/",
    component: () => import("@/views/pushmessages/PushMessages.vue"),
    beforeEnter: mapParamsRouteGuard,
    props: (route) => {
      return {
        queryParams: route.query,
        product: Number(route.params.productProp),
        productLine: Number(route.params.productLineProp),
      };
    },
  },
  {
    name: "PushMessageShortcut",
    path: "/pushnotifications/message/:pushMessageId(\\d+)?/",
    component: () => import("@/views/pushmessages/PushMessageShortcut.vue"),
    props: (route) => {
      return {
        pushMessageId: route.params.pushMessageId,
      };
    },
  },
];

import { RouteRecordRaw } from "vue-router";

import { TargetTypeGuard } from "@/api/target/typedefs";
import { cast } from "@/lib/typing";

import { mapParamsRouteGuard } from "../mapParamsRouteGuard";

export const routesTargetings: RouteRecordRaw[] = [
  {
    name: "TargetingList",
    path: "/:targetType(pushnotifications|inapps)/target-list/:product(\\w+)/:productLine(\\w+)/",
    component: () => import("@/views/targets/Targets.vue"),
    beforeEnter: mapParamsRouteGuard,
    props: (route) => {
      return {
        targetType: cast(TargetTypeGuard, route.params.targetType),
        product: Number(route.params.productProp),
        productLine: Number(route.params.productLineProp),
      };
    },
  },
];

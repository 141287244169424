type Environment = "testing" | "production";

const HOST_TESTING = "pns-console.op-test.net";

const HOST_PRODUCTION = "pns.opera.software";

export function getEnvironment(): Environment | null {
  switch (window.location.host) {
    case HOST_TESTING:
      return "testing";
    case HOST_PRODUCTION:
      return "production";
    default:
      return null;
  }
}

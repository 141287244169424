import camelcaseKeys from "camelcase-keys";
import { computed, Ref, ref } from "vue";

import { httpClient, throwIfBadResponse } from "@/lib/http";
import { cast } from "@/lib/typing";

import { DataState, UserDataGuard } from "./typedefs";

const USER_URL = "/api/users/me";

export interface UserData {
  username: string | null;
  isSuperuser: boolean;
}

export function initialUseUserState(): UserData {
  return {
    username: null,
    isSuperuser: false,
  };
}

export const user: Ref<UserData> = ref(initialUseUserState());
export const state: Ref<DataState> = ref(DataState.LOADING);

async function fetchCurrentUserData() {
  try {
    const response = await httpClient.get(USER_URL);

    await throwIfBadResponse(response);
    const data = await response.json();

    user.value = cast(UserDataGuard, camelcaseKeys(data));
    state.value = DataState.LOADED;
  } catch (error: unknown) {
    state.value = DataState.ERROR;
    throw error;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUserData() {
  const currentUserName = computed(() => user.value.username);

  return {
    user,
    state,
    currentUserName,
    fetchCurrentUserData,
  };
}

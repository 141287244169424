import { computed, ComputedRef, Ref, ref } from "vue";

import { Tag, TagGuard } from "@/api/tags/typedefs";
import { castString } from "@/lib/casts";
import { httpClient, throwIfBadResponse } from "@/lib/http";
import { cast, TypeCastError } from "@/lib/typing";

import { Config, DataState, Menu, NavMenuItem } from "./typedefs";

const CONFIG_URL = "/api/config/";

const state: Ref<DataState> = ref(DataState.LOADING);
export const config: Ref<Config | null> = ref(null);

function convertNavMenuItem(data: unknown): NavMenuItem {
  const navMenuData = data as {
    name?: unknown;
    icon?: unknown;
    route?: unknown;
    product_lines?: unknown;
    features?: unknown;
  };
  const navMenuItem: NavMenuItem = {
    name: castString(navMenuData?.["name"]),
    icon: castString(navMenuData?.["icon"]),
  };

  if (navMenuData["route"]) {
    navMenuItem.route = castString(navMenuData["route"]);
  }

  if (Array.isArray(navMenuData["product_lines"])) {
    navMenuItem.productLines = navMenuData["product_lines"].map((productLine) =>
      convertNavMenuItem(productLine)
    );
  }

  if (Array.isArray(navMenuData["features"])) {
    navMenuItem.features = navMenuData["features"].map((feature) =>
      convertNavMenuItem(feature)
    );
  }

  return navMenuItem;
}

function convertNavMenuItems(data: unknown): NavMenuItem[] {
  if (!Array.isArray(data)) {
    throw new Error("Failed to convert nav menu items.");
  }
  return data.map((item) => convertNavMenuItem(item));
}

function convertMenu(data: unknown): Menu {
  const menuData = data as {
    products?: unknown;
    pages?: unknown;
  };

  if (!menuData.products || !menuData.pages) {
    throw new Error("Failed to convert menu.");
  }

  return {
    products: convertNavMenuItems(menuData.products),
    pages: convertNavMenuItems(menuData.pages),
  };
}

function convertConfig(data: unknown): Config {
  const configData = data as {
    menu?: unknown;
    default_tags: unknown;
  };

  if (!configData.menu || !Array.isArray(configData.default_tags)) {
    throw new TypeCastError("Failed to convert config.");
  }

  return {
    menu: convertMenu(configData.menu),
    defaultTags: configData.default_tags.map((tag) => cast(TagGuard, tag)),
  };
}

async function fetchConfig(): Promise<void> {
  try {
    state.value = DataState.LOADING;

    const response = await httpClient.get(CONFIG_URL);

    await throwIfBadResponse(response);
    const result = await response.json();
    config.value = convertConfig(result);
    state.value = DataState.LOADED;
  } catch (error: unknown) {
    state.value = DataState.ERROR;
    throw error;
  }
}

export const useConfigData = () => {
  const navMenu: ComputedRef<Menu | null> = computed(() => {
    return config?.value?.menu ?? null;
  });

  const defaultTags: ComputedRef<Tag[]> = computed(() => {
    return config.value?.defaultTags ?? [];
  });

  return {
    config,
    navMenu,
    defaultTags,
    fetchConfig,
    state,
  };
};

export type ConfigDataComposable = typeof useConfigData;

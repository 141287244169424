
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { computed, defineComponent, onBeforeUnmount, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { domUtils } from "@/lib/domUtils";
import { sanitizeUrlForIFrame } from "@/lib/sanitize";
import { useFallback } from "@/views/fallback/useFallback";

import { handleLocationChange } from "./helpers";

export default defineComponent({
  name: "Fallback",
  props: {
    targetPath: {
      type: String,
      required: true,
    },
    timestamp: {
      type: String,
      required: false,
      default: () => new Date().getTime().toString(),
    },
  },
  setup(props) {
    const $q = useQuasar();
    const isLoading = ref(true);
    const router = useRouter();
    const sanitizedTargetPath = computed(() =>
      sanitizeUrlForIFrame(props.targetPath)
    );

    watch(
      () => props.targetPath,
      () => (isLoading.value = true)
    );

    const { eventHandler } = useFallback({
      router: router,
      quasar: $q,
      targetPath: ref(props.targetPath),
      sanitizedTargetPath: sanitizedTargetPath,
      locationChangeCallback: handleLocationChange,
    });

    const window = domUtils.getWindow();
    window.addEventListener("message", eventHandler);
    onBeforeUnmount(() => {
      window.removeEventListener("message", eventHandler);
    });

    const onIFrameLoad = () => {
      isLoading.value = false;
    };

    return {
      isLoading,
      onIFrameLoad,
      sanitizedTargetPath,
    };
  },
});

import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { castString } from "@/lib/casts";
import {
  castToProductId,
  castToProductLineId,
  LegacyMappingError,
} from "@/lib/url";

export function mapParamsRouteGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  try {
    const productId = castToProductId(castString(to.params.product));
    const productLineId = castToProductLineId(
      castString(to.params.productLine),
      String(productId)
    );
    to.params.productProp = String(productId);
    to.params.productLineProp = String(productLineId);
  } catch (error: unknown) {
    if (error instanceof LegacyMappingError) {
      next({ name: "NotFound" });
    }
    throw error;
  }
  next();
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_test_device_card_field = _resolveComponent("test-device-card-field")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    class: "device-card",
    "data-test": "testdevice.card"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_icon, {
            size: "md",
            name: "phone_iphone"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _createVNode(_component_test_device_card_field, {
            "data-test": "testdevice.card.field.devicename",
            label: "Device name",
            value: _ctx.testDevice.deviceName
          }, null, 8, ["value"]),
          _createVNode(_component_test_device_card_field, {
            "data-test": "testdevice.card.field.product",
            label: "Product",
            value: _ctx.productLabel
          }, null, 8, ["value"]),
          _createVNode(_component_test_device_card_field, {
            "data-test": "testdevice.card.field.leanplumuserid",
            label: "Leanplum User ID",
            value: _ctx.testDevice.leanplumUserId
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            "data-test": "testdevice.card.button.remove",
            flat: "",
            "text-color": "negative",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('removeDevice')))
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Remove device")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
export type QueryParamsValue = string | null;
export type QueryParams = Record<string, QueryParamsValue | QueryParamsValue[]>;

function stringifyParamValue(paramValue: QueryParamsValue) {
  return paramValue === null ? "" : paramValue;
}

function buildUrlQueryParams(queryParams: QueryParams): string {
  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(queryParams)) {
    if (Array.isArray(value)) {
      for (const subVal of value) {
        searchParams.append(key, stringifyParamValue(subVal));
      }
    } else {
      searchParams.append(key, stringifyParamValue(value));
    }
  }
  return `?${searchParams.toString()}`;
}

export function appendQueryParams(
  baseUrl: string,
  queryParams: QueryParams
): string {
  return baseUrl + buildUrlQueryParams(queryParams);
}

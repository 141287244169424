export enum MessageType {
  LOCATION_CHANGE = "locationChange",
  POPUP_CANCEL_CLICKED = "popupCancelClicked",
  POPUP_OK_CLICKED = "popupOKClicked",
  POPUP_CREATED = "popupCreated",
  FORM_STATUS = "formStatus",
  GO_TO_INAPP_LIST = "goToInAppList",
}

export interface LocationChangeMessagePayload {
  location: string;
  title: string;
}

interface FormStatusMessagePayload {
  message: string;
  status: "succeeded" | "failed";
}

interface LocationChangeMessageData {
  type: MessageType.LOCATION_CHANGE;
  payload: LocationChangeMessagePayload;
}

interface PopupCancelClickedMessageData {
  type: MessageType.POPUP_CANCEL_CLICKED;
}

interface PopupSaveClickedMessageData {
  type: MessageType.POPUP_OK_CLICKED;
}

interface PopupCreatedMessageData {
  type: MessageType.POPUP_CREATED;
}

interface FormStatusMessageData {
  type: MessageType.FORM_STATUS;
  payload: FormStatusMessagePayload;
}

interface GoToInAppListMessageData {
  type: MessageType.GO_TO_INAPP_LIST;
}

type MessageData =
  | LocationChangeMessageData
  | PopupCancelClickedMessageData
  | PopupSaveClickedMessageData
  | PopupCreatedMessageData
  | FormStatusMessageData
  | GoToInAppListMessageData
  | null;

function convertLocationChangePayload(
  rawPayload: unknown
): LocationChangeMessagePayload | null {
  const payload = rawPayload as {
    title?: unknown;
    location?: unknown;
  };

  if (
    typeof payload?.title !== "string" ||
    typeof payload?.location !== "string"
  ) {
    return null;
  }

  return {
    title: payload.title,
    location: payload.location,
  };
}

function convertFormStatusPayload(
  rawPayload: unknown
): FormStatusMessagePayload | null {
  if (rawPayload === null || typeof rawPayload !== "object") return null;
  const payload = rawPayload as {
    message?: unknown;
    status?: unknown;
  };

  if (typeof payload?.message !== "string") return null;

  if (payload?.status !== "succeeded" && payload?.status !== "failed") {
    return null;
  }

  return {
    message: payload.message,
    status: payload.status,
  };
}

export function convertIFrameMessageData(rawData: unknown): MessageData | null {
  if (rawData === null || typeof rawData !== "object") return null;
  const data = rawData as {
    type?: unknown;
    payload?: unknown;
  };

  switch (data?.type) {
    case MessageType.LOCATION_CHANGE: {
      const payload = convertLocationChangePayload(data?.payload);
      if (payload === null) return null;
      return {
        type: MessageType.LOCATION_CHANGE,
        payload,
      };
    }
    case MessageType.FORM_STATUS: {
      const payload = convertFormStatusPayload(data?.payload);
      if (payload === null) return null;
      return {
        type: MessageType.FORM_STATUS,
        payload,
      };
    }
    case MessageType.POPUP_CANCEL_CLICKED:
      return {
        type: MessageType.POPUP_CANCEL_CLICKED,
      };
    case MessageType.POPUP_OK_CLICKED:
      return {
        type: MessageType.POPUP_OK_CLICKED,
      };
    case MessageType.POPUP_CREATED:
      return {
        type: MessageType.POPUP_CREATED,
      };
    case MessageType.GO_TO_INAPP_LIST:
      return {
        type: MessageType.GO_TO_INAPP_LIST,
      };
    default:
      return null;
  }
}

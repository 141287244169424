import { APIError, AuthenticationError, buildErrorMessage } from "@/lib/errors";

import { CSRFTokenProvider, getCSRFTokenCookie } from "./cookies";
import { domUtils } from "./domUtils";
import { appendQueryParams } from "./urlBuilder";

export class DoesNotExistError extends Error {}

const LOGIN_PATHNAME = "/login/";
const HEADER_CSRF_TOKEN = "X-CSRFToken";

export type QueryParamsValue = string | null;
export type QueryParams = Record<string, QueryParamsValue | QueryParamsValue[]>;

export type Json =
  | string
  | number
  | boolean
  | null
  | { [property: string]: Json | undefined }
  | Json[];

type Body = Json | FormData;

export async function throwIfBadResponse(response: Response): Promise<void> {
  if (response.ok) {
    return;
  }
  const data = await response.json();

  const errorMessage = buildErrorMessage(
    response.status,
    response.statusText,
    data
  );
  if (response.status === 401) {
    throw new AuthenticationError(errorMessage);
  }

  if (response.status === 404) {
    throw new DoesNotExistError(errorMessage);
  }

  throw new APIError(errorMessage);
}

function isRedirectedToLogin(url: string): boolean {
  try {
    const urlObj = new URL(url);
    return urlObj.pathname === LOGIN_PATHNAME;
  } catch {
    return false;
  }
}

function buildBody(
  headers: Record<string, string>,
  bodyInit?: Body
): string | FormData | undefined {
  if (bodyInit instanceof FormData) {
    return bodyInit;
  } else if (bodyInit !== undefined) {
    headers["Content-Type"] = "application/json";
    return JSON.stringify(bodyInit);
  } else {
    return undefined;
  }
}

async function shouldRetryAfterCSRFFail(
  response: Response,
  isRetried: boolean
): Promise<boolean> {
  if (isRetried || response.status !== 403) {
    return false;
  }

  // NOTE: You can call response.json() only once.
  // That is why we make a clone here so when response should not be retried
  // it is returned to httpClient caller and he can response.json() once again.
  const responseData = await response.clone().json();
  return (
    responseData?.detail === "CSRF Failed: CSRF token missing or incorrect."
  );
}

export type FetchFunction = (
  input: RequestInfo | URL,
  init?: RequestInit
) => Promise<Response>;

export interface RequestOptions {
  headers?: Record<string, string>;
  signal?: AbortSignal;
}

export class HTTPClient {
  private readonly headers: Record<string, string> = {};
  private onUnauthorizedCallback: (() => void) | null = null;

  constructor(
    private readonly fetchProvider: () => FetchFunction,
    private readonly getCSRFTokenCookieCallback: CSRFTokenProvider
  ) {
    this.headers[HEADER_CSRF_TOKEN] = this.getCSRFTokenCookieCallback();
  }

  private fetch(
    input: RequestInfo | URL,
    init?: RequestInit | undefined
  ): Promise<Response> {
    return this.fetchProvider()(input, init);
  }

  public get(
    url: string,
    queryParams?: QueryParams,
    options?: RequestOptions
  ): Promise<Response> {
    return this.handleRequest(
      "GET",
      queryParams ? appendQueryParams(url, queryParams) : url,
      undefined,
      options
    );
  }

  public post(url: string, body?: Body): Promise<Response> {
    return this.handleRequest("POST", url, body);
  }

  public put(url: string, body?: Body): Promise<Response> {
    return this.handleRequest("PUT", url, body);
  }

  public delete(url: string): Promise<Response> {
    return this.handleRequest("DELETE", url);
  }

  private async handleRequest(
    method: string,
    url: string,
    body?: Body,
    options?: RequestOptions,
    isRetried = false
  ): Promise<Response> {
    const headers: Record<string, string> = {
      ...this.headers,
      ...(options?.headers ?? {}),
    };

    const bodyInit = buildBody(headers, body);
    const fetchOptions = {
      headers,
      method,
      body: bodyInit,
      signal: options?.signal,
    };

    const response = await this.fetch(url, fetchOptions);

    if (isRedirectedToLogin(response.url)) {
      throw new AuthenticationError("Redirected to login");
    }
    // TODO (PNS-1907): Drop when implementing login modal
    if (response.status === 401) {
      this?.onUnauthorizedCallback?.();
      throw new AuthenticationError(
        `Server returned 401 Unauthorized response when requested ${url}`
      );
    }

    if (await shouldRetryAfterCSRFFail(response, isRetried)) {
      await this.updateCSRFToken();
      return await this.handleRequest(method, url, body, options, true);
    }
    return response;
  }

  public async updateCSRFToken() {
    this.headers[HEADER_CSRF_TOKEN] = await this.obtainNewCSRFToken();
  }

  public async obtainNewCSRFToken(): Promise<string> {
    // make request that sets new CSRF Token cookie
    try {
      await this.get("/login/");
    } catch (error: unknown) {
      if (error instanceof Error && error?.message === "Redirected to login") {
        // pass
      } else {
        throw error;
      }
    }
    return this.getCSRFTokenCookieCallback();
  }

  public setOnUnauthorizedCallback(callback: () => void): void {
    this.onUnauthorizedCallback = callback;
  }
}

export const httpClient = new HTTPClient(
  // Fetch is wrapped into a arrow fn. Thanks to that domUtils.getWindow().fetch
  // is not called on module init time but on call to fetch
  // and domUtils.getWindow().fetch can be easily mocked in tests importing this.
  () => (input: RequestInfo | URL, init?: RequestInit | undefined) =>
    domUtils.getWindow().fetch(input, init),
  getCSRFTokenCookie
);

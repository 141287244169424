import { computed, ComputedRef, ref, Ref } from "vue";

import { NavMenuItem } from "@/composables/typedefs";

const NO_SELECTION_ICON = "more_horiz";

export interface UseNavMenuExpandableOptions {
  items: Ref<NavMenuItem[]>;
  selectedItem: Ref<Partial<NavMenuItem> | null>;
  caption: Ref<string>;
  itemClickedCallback: (item: NavMenuItem) => void;
}

export const NO_NAME_SYMBOL = "--";

export function useNavMenuExpandable({
  items,
  selectedItem,
  itemClickedCallback,
}: UseNavMenuExpandableOptions) {
  const icon: ComputedRef<string> = computed(() => {
    const selectedIcon: string = selectedItem.value?.icon ?? "";
    if (selectedIcon) {
      return `img:${selectedIcon}`;
    }
    return NO_SELECTION_ICON;
  });

  const openedPermanently: Ref<boolean> = ref(false);

  const opened: Ref<boolean> = ref(!selectedItem.value);
  const itemClicked = (item: NavMenuItem) => {
    itemClickedCallback(item);
    if (!openedPermanently.value) {
      opened.value = false;
    }
  };

  const isDisabled: ComputedRef<boolean> = computed(() => {
    return !items.value.length;
  });

  const label: ComputedRef<string> = computed(() => {
    return selectedItem.value?.name ?? NO_NAME_SYMBOL;
  });

  return { icon, itemClicked, opened, isDisabled, label, openedPermanently };
}

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isDisabled)
      ? (_openBlock(), _createBlock(_component_q_tooltip, {
          key: 0,
          anchor: "top middle"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" No items to choose from ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_q_expansion_item, {
      modelValue: _ctx.opened,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.opened) = $event)),
      "expand-separator": "",
      "data-test": "navmenuexpandable",
      label: _ctx.label,
      caption: _ctx.caption,
      icon: _ctx.icon,
      disable: _ctx.isDisabled,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openedPermanently = !_ctx.openedPermanently))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
              return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                key: item.name,
                clickable: "",
                "data-test": "navmenu.menu-item",
                onClick: ($event: any) => (_ctx.itemClicked(item))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, { avatar: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_img, {
                        src: item.icon,
                        height: "25px",
                        fit: "scale-down"
                      }, null, 8, ["src"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_item_section, { "data-test": "navmenu.item" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"])), [
                [_directive_ripple]
              ])
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "label", "caption", "icon", "disable"])
  ]))
}
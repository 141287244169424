
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    message: {
      type: String,
      required: false,
      default: "An error has occurred",
    },
    icon: {
      type: String,
      required: false,
      default: "error_outline",
    },
  },
});

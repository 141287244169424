import { RouteRecordRaw } from "vue-router";

import { domUtils } from "@/lib/domUtils";

import Home from "../views/home/Home.vue";
import NotFound from "../views/NotFound.vue";
import { routesFallback } from "./routes/routesFallback";
import { routesInApps } from "./routes/routesInApps";
import { routesPushMessages } from "./routes/routesPushMessages";
import { routesTargetings } from "./routes/routesTargetings";
import { routesTestDevices } from "./routes/routesTestDevices";

export const routes: RouteRecordRaw[] = [
  {
    name: "Login",
    path: "/login",
    redirect: () => {
      if (process.env.VUE_APP_PROXY_DISABLED !== undefined) {
        // TODO(PNS-1597): Drop when implementing login page on new FE.
        throw new Error(
          "Tried to redirect to login on VUE_APP_PROXY_DISABLED."
        );
      }
      const windowObj = domUtils.getWindow();
      windowObj.location.href = `/login/?next=${windowObj.location.pathname}${windowObj.location.search}`;
      return "";
    },
  },
  {
    name: "Home",
    path: "/",
    component: Home,
  },
  ...routesPushMessages,
  ...routesInApps,
  ...routesTargetings,
  ...routesTestDevices,
  {
    name: "NotFound",
    path: "/not-found",
    component: NotFound,
  },
  ...routesFallback,
];

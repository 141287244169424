import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, isRef as _isRef } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  "data-test": "header.label.loggeduser"
}

import { toRefs } from "vue";
import { Router } from "vue-router";

import NavMenu from "./components/navmenu/NavMenu.vue";
import { redirectToLogout } from "./lib/logout";
import { Sentry } from "./lib/sentry";
import { useApp } from "./useApp";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  props: {
    sentry: {},
    router: {}
  },
  setup(__props: any) {

const props = __props;

const {
  leftDrawerOpen,
  currentUserName,
  isUserError,
  isUserLoading,
  isMenuLoading,
  isMenuError,
  menu,
  toggleLeftDrawer,
  fetchOrRouteToLogin,
  config,
} = useApp(toRefs(props));

fetchOrRouteToLogin();

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_q_drawer = _resolveComponent("q-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "hHh LpR fFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_header, {
        elevated: "",
        class: "bg-primary text-white"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                dense: "",
                flat: "",
                round: "",
                icon: "menu",
                "data-test": "header.button.togglemenu",
                onClick: _unref(toggleLeftDrawer)
              }, null, 8, ["onClick"]),
              _createVNode(_component_q_toolbar_title, null, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" PNS Console ")
                ])),
                _: 1
              }),
              (_unref(isUserError))
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Failed to fetch current user"))
                : (!_unref(isUserLoading))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                      _createVNode(_component_q_btn, {
                        stretch: "",
                        flat: "",
                        label: `Logged in as ${_unref(currentUserName)}`
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_menu, { fit: "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_list, null, {
                                default: _withCtx(() => [
                                  _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                    clickable: "",
                                    onClick: _unref(redirectToLogout)
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, { avatar: "" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_icon, { name: "logout" })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_label, null, {
                                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                                              _createTextVNode("Log out")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"])), [
                                    [_directive_close_popup]
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_drawer, {
        modelValue: _unref(leftDrawerOpen),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(leftDrawerOpen) ? (leftDrawerOpen).value = $event : null)),
        "show-if-above": "",
        side: "left",
        bordered: ""
      }, {
        default: _withCtx(() => [
          _createVNode(NavMenu, {
            menu: _unref(menu) ?? undefined,
            "is-loading": _unref(isMenuLoading),
            "is-error": _unref(isMenuError)
          }, null, 8, ["menu", "is-loading", "is-error"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_q_page_container, { class: "page-container" }, {
        default: _withCtx(() => [
          (_unref(config) !== null)
            ? (_openBlock(), _createBlock(_component_router_view, {
                key: 0,
                config: _unref(config)
              }, null, 8, ["config"]))
            : (_openBlock(), _createBlock(_component_q_spinner, {
                key: 1,
                "data-test": "app.loading",
                size: "xl",
                color: "primary"
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})
import { domUtils } from "./domUtils";

export type CSRFTokenProvider = () => string;

export function getCookie(name: string): unknown {
  let cookieValue = null;
  const documentCookie = domUtils.getWindow().document.cookie;
  if (documentCookie !== "") {
    const cookies = documentCookie.split(";");
    for (const rawCookie of cookies) {
      const cookie = rawCookie.trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function getCSRFTokenCookie(): string {
  return String(getCookie("csrftoken") ?? "");
}

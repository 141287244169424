import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col row items-start q-gutter-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_test_device_card = _resolveComponent("test-device-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.testDevices, (testDevice) => {
      return (_openBlock(), _createBlock(_component_test_device_card, {
        key: testDevice.id,
        "test-device": testDevice,
        onRemoveDevice: ($event: any) => (_ctx.$emit('removeDevice', testDevice.id))
      }, null, 8, ["test-device", "onRemoveDevice"]))
    }), 128))
  ]))
}
export function parseEnvBool(rawEnv: string | undefined): boolean {
  if (rawEnv === undefined) return false;
  switch (rawEnv.toLowerCase()) {
    case "true":
    case "1":
      return true;
    case "false":
    case "0":
      return false;
    default:
      throw new Error(`Unexpected bool env value ${rawEnv}`);
  }
}
